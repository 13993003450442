<template>
  <a-modal :visible="visible" title="脱敏策略确认" @cancel="handleCloseModal" :width="800">
    <a-table :columns="columns" :data-source="datasource" size="small">
      <template slot="name" slot-scope="record">
        {{ record.confirmName.join('/') }}
      </template>
      <template slot="type" slot-scope="record">
        {{ types[record.ruleType] && types[record.ruleType].name }}
      </template>
      <template slot="value" slot-scope="record">
        {{ record.ruleExpr }}
      </template>
    </a-table>
    <div class="footer">
      <a-button type="primary" @click="handleConfirm">确定</a-button>
      <a-button @click="handleCloseModal">取消</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: 'RulesConfirmModal',
  props: {
    visible: Boolean,
    rules: Object,
    datasource: Array,
    types: Object,
    handleCloseModal: Function
  },
  data() {
    return {
      columns: [
        {
          title: '实例名/库名/表名/列名',
          scopedSlots: { customRender: 'name' }
        }, {
          title: '脱敏算法',
          scopedSlots: { customRender: 'type' },
          width: 100
        }, {
          title: '参数值',
          scopedSlots: { customRender: 'value' },
          width: 120
        }
      ]
    };
  },
  methods: {
    async handleConfirm() {
      const {
        adds,
        updates,
        deletes
      } = this.rules;
      const res = await this.$services.modifyDesensitizeRule({
        data: {
          addRules: Object.values(adds),
          updateRules: Object.values(updates),
          deleteRules: Object.values(deletes)
        },
        msg: '编辑脱敏规则成功'
      });

      if (res.success) {
        this.handleCloseModal();
        this.$router.push({
          name: 'System_Desensitization'
        });
      }
    }
  }
};
</script>

<style scoped>

</style>
