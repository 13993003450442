<template>
  <a-modal :visible="visible" @cancel="handleCloseModal" title="脱敏规则预览" :width="480">
    <a-form-model :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-model-item label="原始数据">
        <div style="display: flex;">
          <a-input v-model="form.value" placeholder="请输入原始数据" style="width: 300px;"/>
          <a-button style="width: 60px;margin-left: 10px;" @click="preview">测试</a-button>
        </div>
      </a-form-model-item>
      <a-form-model-item label="脱敏数据">
        <a-input placceholder="" v-model="form.ruledValue" type="textarea" style="width: 374px;"/>
      </a-form-model-item>
    </a-form-model>
    <div class="footer">
      <a-button style="width: 120px;" @click="handleCloseModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PreviewModal',
  props: {
    visible: Boolean,
    column: {},
    ruleData: {},
    handleCloseModal: Function
  },
  data() {
    return {
      form: {
        value: '',
        ruledValue: ''
      }
    };
  },
  methods: {
    async preview() {
      const res = await this.$services.desensitizeRuleSample({
        data: {
          expr: this.ruleData.ruleExpr || '',
          ruleType: this.ruleData.ruleType || '',
          sampleStr: this.form.value
        }
      });

      if (res.success) {
        Vue.set(this.form, 'ruledValue', res.data);
      }
    }
  }
};
</script>

<style scoped>

</style>
